$accentColor: #1c75bc;
$backgroundColor: #f0f0f0;
$primaryColor: #606161;
$secondaryColor: #606161;
$error: #ff5959;
$success: #2eb086;
$white: #ffffff;

$font-10: 10px;
$font-12: 12px;
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-24: 24px;
$font-28: 28px;
$font-32: 32px;

$regular: 400;
$medium: 500;
$semibold: 600;

$firaSans: "Fira Sans", sans-serif;
