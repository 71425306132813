.tnc-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    display:flex;
    text-align: justify; 
  }
  
 
  
  .tnc-container {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
  }
  
  .tnc-container h3 {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .tnc-container p {
    margin-bottom: 10px;
    word-spacing: 0.5;
    line-height: 1.5;
  }
  
  /* Add more styles as needed */
  